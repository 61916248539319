<template>
  <div class="page-main-bg" id="clientsPage">
    <v-container
      :class="[
        'main-container team-container position-relative',
        { 'list-view': !gridView, 'grid-view': gridView }
      ]"
    >
      <!-------------------------------------------->
      <!-------------Toolbar------------------------>
      <!-------------------------------------------->
      <!-- TODO  use a prop STATUS=false for hide toggle button when no there any associaiton -->
      <tool-bar
        :breadcrumbs="breadcrumbs"
        :toggle-drawer="toggleDrawer"
        @toggleCards="cardsInfoState = !cardsInfoState"
        title="الجمعيات"
      />

      <!------------------------------------------------->
      <!---------- If no there any associaiton ---------->
      <!------------------------------------------------->
      <v-row
        v-if="false"
        class="align-center justify-center"
        style="height: 60vh"
      >
        <v-col cols="12" class="d-flex flex-column align-center justify-center">
          <img :src="assoicationsIcon" alt="assoication" />
          <p class="font-14 mt-2">لا يوجد جمعيات مضافة حتى الآن</p>
          <v-btn
            @click="openModal"
            class="font-weight-bold fast-actions-btn pa-5 font-12"
            color="primary"
            large
          >
            <img
              class="mx-2"
              src="@/assets/img/svgs/assoication.svg"
              loading="lazy"
            />
            إضافة جمعية
          </v-btn>
        </v-col>
      </v-row>

      <div v-else>
        <!-------------------------------------------->
        <!-------------ClientStat--------------------->
        <!-------------------------------------------->

        <clientStat
          v-if="$hasPermission('statistics', 'statistics', 'list')"
          statesType="clients"
          :cards-info-state="cardsInfoState"
        />

        <!-------------------------------------------->
        <!-------------Skeleton loader---------------->
        <!-------------------------------------------->

        <!-- <SkeletonLoader v-if="isPageLoading" /> -->

        <!----------------------------->
        <!---- Data table client ------>
        <!----------------------------->

        <v-row class="mt-6">
          <v-col class="property-col" cols="12">
            <DataTableAssoications
              :headers="headers"
              :assoications="assoications"
            />
          </v-col>
        </v-row>

        <!------------------------------------------------------->
        <!-------User actions (addClient & fastActions)---------->
        <!------------------------------------------------------->

        <div
          class="d-flex align-center justify-end ml-md-8 fast-actions"
          v-if="hasPermission"
        >
          <v-btn
            @click="openModal"
            class="font-weight-bold fast-actions-btn pa-5 font-12"
            color="primary"
            large
          >
            <img
              class="mx-2"
              src="@/assets/img/svgs/assoication.svg"
              loading="lazy"
            />
            إضافة جمعية
          </v-btn>

          <!----------------------------->
          <!-------Fast actions---------->
          <!----------------------------->
          <fastActions />
        </div>
      </div>

      <!----------------------------->
      <!------ Add client modal ----->
      <!----------------------------->
      <AddAssoicationModal
        v-if="dialogState"
        :dialogState="dialogState"
        @close-client-dialog="closeModals"
      />

      <!------------------------------>
      <!--------Pagination------------>
      <!------------------------------>

      <div class="text-center mt-6 mb-5" v-show="viewPagination">
        <Pagination
          :pagination="pagination"
          @onChange="handleOnchangePagination"
        />
      </div>
    </v-container>
  </div>
</template>

<style>
.v-icon.v-icon--dense {
  font-size: 25px !important;
}
</style>
<script>
import { mapMutations, mapState } from 'vuex'
import ToolBar from '@/components/listing/ToolBar'
import clientStat from '@/components/properties/RealEstatStat'
import DataTableAssoications from '@/components/associations/DataTableAassoications'
import Pagination from '@/components/helper/pagination.vue'
import fastActions from '@/components/helper/fastActions'
import '@/styles/material-dashboard/_properties.scss'
import '@/styles/material-dashboard/_team.scss'

export default {
  components: {
    AddAssoicationModal: () =>
      import('@/components/modals/AddAssoicationModal'),
    fastActions,
    ToolBar,
    clientStat,
    DataTableAssoications,
    Pagination
  },
  data() {
    return {
      gridView: true,
      cardsInfoState: true,
      dialogState: false,
      // isPageLoading: false,
      pagination: {
        pageNumber: 1,
        pagesCount: 0,
        pageSize: 12,
        count: 0
      },
      breadcrumbs: [
        {
          text: 'اتحاد الملاك',
          disabled: true,
          to: { path: '/dashboard/associations' }
        }
      ],
      headers: [
        {
          text: 'اسم الجمعية',
          value: 'one'
        },
        { text: 'رئيس الجمعية', value: 'two' },
        { text: 'عدد الاعضاء', value: 'three' },
        { text: 'تاريخ الانشاء', value: 'four' },
        { text: 'اسم المسؤول', value: 'fife' }
      ],
      assoications: [
        {
          one: 'جمعية مجمع العزيزية',
          two: 'طه خالد الكبير',
          three: '7',
          four: '21 يناير 2021',
          fife: 'عادل الهاشم'
        },
        {
          one: 'جمعية مجمع العزيزية',
          two: 'طه خالد الكبير',
          three: '5',
          four: '21 يناير 2021',
          fife: 'عادل الهاشم'
        }
      ],
      assoicationsIcon: require('@/assets/img/svgs/assoicationNoData.svg')
    }
  },
  mounted() {
    this.$emit('updateInformativeTip', this.$route?.meta?.informativeTip)
  },
  computed: {
    ...mapState('accountState', ['currentEnv', 'user']),
    viewPagination() {
      return this.pagination.pagesCount > 1
    },
    hasPermission() {
      return this.$hasPermission('users', 'clients', 'add')
    }
  },
  methods: {
    ...mapMutations('appState', ['toggleDrawer', 'addNotification']),
    // Pagination - recalculate and handle when clicked on pagination
    // TODO convert to computed
    recalculatePagination(res) {
      this.pagination.pagesCount = Math.ceil(
        res.count / this.pagination.pageSize
      )
      this.pagination.count = res.count
    },
    handleOnchangePagination() {},
    openModal() {
      this.dialogState = true
    },
    closeModals() {
      this.dialogState = false
    }
  },
  metaInfo() {
    return {
      title: 'الجمعيات'
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/material-dashboard/fastactions.scss';
@import '@/styles/material-dashboard/_statistics-cards.scss';
</style>
